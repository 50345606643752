@use "src/_app/styles/mixins.scss" as *;
@mixin contentWidth {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 196px 0;

  @include respond(laptop) {
    padding: 196px 32px;
  }

  @include respond(tablet) {
    padding-bottom: 135px;
  }

  @include respond(tabletS) {
    padding: 137px 16px;
  }
}

.start__accepting__container {
  @include contentWidth;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url('/img/main/bg-start-accepting.png');
  background-position: bottom;
  background-repeat: no-repeat;

  @include respond(mobileL) {
    background-image: url('/img/main/bg-start-accepting-mobile.png');
    background-size: cover;
  }

  h2 {
    @include tTitle_1_bold;
    color: var(--white);
    max-width: 819px;
    text-align: center;
    line-height: 72px;
    margin: 0 0 40px;

    @include respond(tabletS) {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
    }

    @include respond(mobileL) {
      margin-bottom: 32px;
    }
  }

  .button {
    button {
      @include tSubtitle_Semibold;
      text-transform: uppercase;

      width: 217px;
      height: 60px;

      @include respond(mobileL) {
        width: 195px;
        text-transform: unset;
        line-height: 20px;
      }
    }
  }

  a.signUp_button {
    height: 60px;

    p {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
