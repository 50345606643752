@use "src/_app/styles/mixins.scss" as *;
.container {
  width: 100%;
  padding: 0 80px;
  overflow: hidden;

  @include respond(laptop) {
    padding: 0 32px;
  }

  @include respond(tabletS) {
    padding: 0 16px;
  }
}

.Toastify__toast {
  background: red;
}

:root {
  .Toastify__toast {
    background: red;
  }
}

.top_container {
  background: var(--mainPrimary);
  border-bottom: 1px solid #000;

  a.getStarted_button {
    height: 60px;

    p {
      text-transform: uppercase;
      font-weight: 600;
    }

    @include respond(tablet) {
      display: none;
    }
  }

  .top__content {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    background-image:
      url('/img/common/bg-main.png'), url('/img/common/bg-main-front.png');
    background-repeat: no-repeat;
    background-size: 60%, 70%;
    background-position:
      50% 1%,
      50% 14%;

    @include respond(laptop) {
      background-image:
        url('/img/common/bg-main.png'),
        url('/img/common/bg-main-front-tablet.png');
      background-size: 89%, 95%;
      background-position:
        50% 1%,
        50% 10%;
    }

    @include respond(tablet) {
      background-image: none;
    }

    .top__titles {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include contentWidth;

      max-width: 900px;
      padding: 0 133px;
      color: var(--white);

      @include respond(laptop) {
        padding: 0 43px;
      }

      @include respond(tablet) {
        padding: 0 16px;
      }

      h1 {
        margin: 0 0 32px;
        padding-top: 8px;

        @include tTitle_1_banner;

        @include respond(tablet) {
          font-weight: 700;
          font-size: 36px;
          line-height: 46px;
          padding-top: 8px;
          max-width: 100%;
          white-space: break-spaces;
          margin-bottom: 8px;
        }
      }

      span {
        display: block;
        margin: 0;
        padding-top: 92px;

        @include tSubtitle_Bold;

        text-transform: uppercase;

        @include respond(tablet) {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          padding-top: 32px;
        }
      }
    }
  }
}

.how_it_works_container {
  background: var(--secondary);
  padding: 45px 80px;

  @include respond(laptop) {
    border-radius: 0;
    padding: 45px 0 29px;
  }

  @include respond(tabletS) {
    padding: 40px 0 0;
  }

  .how_it_works {
    @include contentWidth;

    background: var(--bg_3);
    border-radius: 16px;
    overflow: hidden;
    padding: 48px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include respond(laptop) {
      border-radius: 0;
      padding: 32px;
    }

    @include respond(tablet) {
      background: var(--secondary);
      padding: 0 32px 16px;
      flex-direction: column;
      gap: 32px;
    }

    @include respond(tabletS) {
      padding: 0 16px 16px;
    }

    .description {
      max-width: 517px;
      color: var(--primary);

      @include respond(laptop) {
        max-width: 433px;
      }

      @include respond(tablet) {
        max-width: none;
      }

      h2 {
        margin: 0 0 84px;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;

        @include respond(laptop) {
          margin-bottom: 92px;
          font-size: 42px;
          line-height: 52px;
        }

        @include respond(tablet) {
          margin-bottom: 32px;
        }

        @include respond(tabletS) {
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 48px;

        @include respond(tabletS) {
          gap: 24px;
        }

        li {
          display: flex;
          align-items: flex-start;

          &:first-child {
            h3 {
              margin: 0 36px 0 0;

              @include respond(laptop) {
                margin: 0 29px 0 0;
              }

              @include respond(tabletS) {
                margin: 0 21px 0 0;
              }
            }
          }

          h3 {
            margin: 0 31px 0 0;
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;

            @include respond(laptop) {
              font-size: 42px;
              line-height: 52px;
              margin: 0 24px 0 0;
            }

            @include respond(tabletS) {
              font-weight: 600;
              font-size: 36px;
              line-height: 44px;
              margin: 0 16px 0 0;
            }
          }

          h4 {
            margin: 0;

            @include tTitle_3-semibold;

            @include respond(tabletS) {
              font-size: 18px;
              line-height: 24px;
            }
          }

          p {
            margin: 0;

            @include tSubtitle;
          }
        }
      }
    }

    .demo {
      margin-right: 64px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 480px;
      min-width: 370px;
      width: 40%;

      @include respond(laptop) {
        margin-right: 0;
      }

      @include respond(tablet) {
        max-width: none;
        min-width: 0;
        width: 100%;
      }

      .types {
        display: flex;

        p {
          margin: 0;
          cursor: pointer;

          @include tTitle_3-semibold;
          color: var(--text_1);

          &.active {
            color: var(--primary);
          }

          &:first-child {
            margin-right: 24px;
          }
        }
      }

      .hint {
        border-radius: 10px;
        padding: 12px;
        background: var(--black);
        color: var(--white);
        max-width: 480px;

        @include tSubtitle;

        @include respond(tablet) {
          max-width: none;
          width: 100%;
        }
      }
    }
  }
}

.cryptocurrencies_container {
  background: var(--secondary);
  padding-bottom: 45px;

  @include respond(tabletS) {
    padding: 0 0 20px;
  }

  .cryptocurrencies {
    @include contentWidth;

    background: var(--bg_1);
    border-radius: 16px;
    overflow: hidden;
    padding: 48px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    @include respond(laptop) {
      padding: 32px;
    }

    @include respond(tabletS) {
      padding: 32px 16px;
      border-radius: 0;
      display: flex;
      flex-direction: column-reverse;
      gap: 34px;
    }

    .crypto_image {
      max-width: 80%;

      @include respond(laptop) {
        max-width: 90%;
      }

      @include respond(tabletS) {
        max-width: none;
        width: 100%;
      }
    }

    div.cryptocurrencies_text {
      max-width: 387px;

      @include respond(laptop) {
        max-width: 341px;
      }

      @include respond(tabletS) {
        max-width: none;
        text-align: center;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 16px;

        color: var(--white);

        @include tTitle_1_form;

        @include respond(laptop) {
          @include tTitle_2_bold;
        }

        @include respond(tabletS) {
          @include tTitle_3-semibold;
          line-height: 44px;
        }
      }

      p {
        margin: 0;

        @include tSubtitle;
        color: #888;

        @include respond(tabletS) {
          @include tCaption;
        }
      }
    }
  }
}

.accordion__container {
  background: var(--bg_18);

  @include respond(tablet) {
    padding: 0;
  }
}

.features__container {
  background-color: var(--black);
}

.media_container {
  background: #000;
  padding-top: 90px;
  padding-bottom: 90px;
  color: var(--primary);

  @include respond(tabletS) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.media {
  @include contentWidth;
  display: flex;
  flex-direction: column;
  gap: 56px;

  .infinite_container {
    background: #000;

    .infinite_item {
      display: flex;
      align-items: center;
      height: 80px;

      &.main {
        height: auto;
      }
    }
  }
}

.image {
  height: 58px;
}

.support__coins__container {
  background-color: var(--bg_19);
}

.accept__steps__container {
  background-color: var(--black);
}

.plugins__container {
  background: var(--bg_20);
  padding-top: 103px;
  padding-bottom: 100px;

  @include respond(tablet) {
    padding: 0;
  }
}

.start__accepting__container {
  background: linear-gradient(180deg, #000 0%, #101010 100%);

  @include respond(laptop) {
    padding: 0;
  }
}

.blog_container {
  background: var(--bg_3);
  padding-top: 90px;
  padding-bottom: 90px;
  color: var(--primary);

  @include respond(tabletS) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .blog {
    @include contentWidth;

    display: flex;
    flex-direction: column;

    h3 {
      margin: 0 0 56px;

      color: var(--primary);

      @include tTitle_1_form;

      @include respond(laptop) {
        @include tTitle_2_bold;
      }

      @include respond(tabletS) {
        @include tTitle_3-semibold;
        line-height: 44px;
        margin-bottom: 30px;
      }
    }

    @include swiper($shadowPad: 0, $bg: var(--bg_3));

    .swiper_list {
      overflow: visible;
    }
  }
}
